import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Urls from './Urls'

import './Search.css';


const Search = () =>  {
    let navigate = useNavigate();
    const [value, setValue] = useState("");
    
    const submitForm = (e) => {
        e.preventDefault(); 
        navigate("/results?searchall=" + value);
    }
    
    return(
        <div className="page-container">
            <h1>Μητρώο Προτύπων Τίτλων Σπουδών</h1>
            <p className='description'>
                

Η εφαρμογή eDiplomas Blueprints έχει στόχο να διευκολύνει τα ΑΕΙ στην προετοιμασία και ένταξη των προτύπων τίτλων σπουδών τους στην πλατφόρμα eDiplomas. Ο πρότυπος τίτλος σπουδών αφορά στη σταθερή μήτρα κάθε τίτλου που έχει εκδοθεί και περιλαμβάνει όλα τα στοιχεία πλην αυτών που προσδιορίζουν τον πτυχιούχο, το βαθμό του πτυχίου, την ημερομηνία ανακήρυξης. Κάθε πρότυπος τίτλος λαμβάνει ένα μοναδικό εθνικό αναγνωριστικό κωδικό. Οι πρότυποι τίτλοι σπουδών αποτελούν δομικό στοιχείο των ψηφιακών βεβαιώσεων κτήσης τίτλου καθώς και της πληροφορίας που θα επικοινωνείται από την υπηρεσία eDiplomas σε φορείς εντός ή εκτός χώρας.
            </p>
            <p className='redirectText'>Για να συνδεθείτε ως διαχειριστής και να επεξεργαστείτε λεπτομέρειες τίτλων πλοηγηθείτε στην πλατφόρμα <a className="dashboardRedirect" href={Urls.dashboardUrl}>διαχείρισης προτύπων τίτλων σπουδών.</a></p>
            <div className="searchArea">
                <form onSubmit={submitForm}>
                <div className="inputContainer">
                    <input placeholder={"Αναζήτηση Τίτλων Σπουδών..."} id="searchInput" type="text" onChange={e => setValue(e.target.value)}></input>
                </div>
                <div className="searchExtra">
                    <button type="submit" className='primary turnLighter'>Αναζήτηση</button>
                    <br/><br/><a onClick={() => navigate("/results")}>Σύνθετη Αναζήτηση</a>
                </div>
                </form>
            </div>

        </div>
    );
}

export default Search;