import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import eDiplomasLogo from './icons/eDiplomasLogo.svg';
import mineduLogo from './icons/minedu.svg';
import govGr from './icons/govGr.svg';
import './Header.css';

const Typewriter = (props) => {
  const [text, setText] = useState('');
  const [index, setIndex] = useState(1);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(index + 1);
      setText(props.text.substr(0, index));
      clearInterval(timer);
    }, 50);
  }, [text]);

  return <span className="tag">{text}</span>;
};

const Header = (props) => {
  const [userMenu, setUserMenu] = useState(false);

  const toggleUserMenu = () => {
    setUserMenu(!userMenu);
  };
  return (
    <div>
      <div className="Header">
        {props.developer ? (
          <h1 id="developer-header">
            <Link to="/">
              <img id="img-logo" src={eDiplomasLogo} />

              <Typewriter text="eDiplomas/" />
            </Link>
            <img id="img-govgr" src={govGr} />
          </h1>
        ) : (
          <>
            <div className="header-area">
              <Link to="/">
                <span className="logo-area">
                  <span id="eDiplomasLogo">
                    <img className="img-logo" src={eDiplomasLogo} />
                    <span className="logo-text">eDiplomas</span>
                  </span>
                  <span className="logo-split" />
                  <img className="img-logo" id="img-minedu" src={mineduLogo} />
                </span>
              </Link>
              <img id="img-govgr" src={govGr} />
            </div>
          </>
        )}
        {userMenu && (
          <div id="mobile-menu">
            <ul>
              <li>
                <span>
                  <span className="fas fa-user" />
                </span>
              </li>
              <li>
                <Link to="/organisation/redirect/logout">
                  {props.polyglot.t(`Organisation.logout.logout`)}
                </Link>
              </li>
            </ul>
          </div>
        )}
        <div className="header-border" />
      </div>
    </div>
  );
};
export default Header;
