import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation} from "react-router-dom";

import Urls from './Urls'

import { Spinner } from './Spinner';
import foeData from './data/foe';
import './Result.css'


const FoeString = (props) => {
  return props.foeList.map((foeCode, i) => (
    <React.Fragment key={`${i}`}>
      {i > 0 && <br/>}
      {foeData.find(f => parseInt(f.value) === parseInt(foeCode)).name}
    </React.Fragment>
  ));
}

const Result = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const hasPreviousState = location.key !== "default";
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDetailedResult = async () => {
      setLoading(true)
      try {
        const res = await fetch(Urls.blueprintsUrl + '/templates/?id=' + id, {
          method: 'GET',
        });
        const resJson = await res.json()
        console.log(resJson[0]);
        setData(resJson[0])
        setLoading(false)
      } catch (e) {
        console.log(e);
        setLoading(false)
      }
    };
    getDetailedResult()
  }, [id]);


  return (
    <div className='result-wrapper'>
      <div className='container'>
        {loading ? <Spinner className='spinner' cx={120} cy={120} r={50} /> :
          data ?
            <div className='table-container'>
              {hasPreviousState &&  <button
                className="back"
                onClick={() => navigate(-1) }
              >
                <span className='fas fa-arrow-left'></span>
                {' '}Επιστροφή
              </button>}
            <h2>Τίτλος Σπουδών</h2>
            <table>
              <tbody>
                <tr>
                  <td>Τίτλος</td>
                  <td>{data.title}</td>
                </tr>
                <tr>
                  <td>Τίτλος (Αγγλικά)</td>
                  <td>{data.title_en ? data.title_en : '-'}</td>
                </tr>
                <tr>
                  <td>Ειδίκευση/Κατεύθυνση</td>
                  <td>{data.major ? data.major : '-'}</td>
                </tr>
                <tr>
                  <td>Πρόγραμμα Σπουδών</td>
                  <td>{data.curriculum.name}</td>
                </tr>
                <tr>
                  <td>Πρόγραμμα Σπουδών (Αγγλικά)</td>
                    <td>{data.curriculum.name_en ? data.curriculum.name_en : '-'}</td>
                </tr>
                <tr>
                  <td>Κωδικός Πανελληνίων </td>
                  <td>{data.curriculum.minedu_id}</td>
                </tr>
                <tr>
                  <td>Γνωστικά Αντικείμενα (ISCED-F)</td>
                    <td>{data.curriculum.foe_list ? <FoeString foeList={data.curriculum.foe_list}/> : '-'}</td>
                </tr>
                <tr>
                  <td>Ίδρυμα </td>
                  <td>{data.issuer.institution}</td>
                </tr>
                <tr>
                  <td>Αρμόδιο Τμήμα</td>
                  <td>{data.curriculum.maintainer.unit}</td>
                </tr>
                <tr>
                  <td>Αρμόδιο Τμήμα (Αγγλικά)</td>
                  <td>{data.curriculum.maintainer.name_en ? data.curriculum.maintainer.name_en : '-'}</td>
                </tr>
                <tr>
                  <td>Τμήμα Έκδοσης</td>
                  <td>{data.issuer.unit}</td>
                </tr>
                <tr>
                  <td>Τμήμα Έκδοσης (Αγγλικά)</td>
                  <td>{data.issuer.name_en ? data.issuer.name_en : '-'}</td>
                </tr>
                <tr>
                  <td>Επίπεδο Σπουδών (ISCED 2011)</td>
                  <td>{data.curriculum.level}</td>
                  </tr>
                  <tr>
                    <td>Σημειώσεις Τίτλου</td>
                    <td>{data.printable_notes ? data.printable_notes : '-'}</td>
                  </tr>
                  <tr>
                    <td>Σημειώσεις Τίτλου (Αγγλικά)</td>
                    <td>{data.printable_notes_en ? data.printable_notes_en : '-'}</td>
                  </tr>
                <tr>
                  <td>Κωδικός Template</td>
                  <td>{data.provider_key}</td>
                </tr>
                <tr>
                  <td>Schac</td>
                  <td>{data.issuer.schac ? data.issuer.schac : '-'}</td>
                </tr>
                <tr>
                  <td>Pic</td>
                  <td>{data.issuer.pic ? data.issuer.pic : '-'}</td>
                </tr>
                <tr>
                  <td>Erasmus Id</td>
                  <td>{data.issuer.erasmusId ? data.issuer.erasmusId : '-'}</td>
                </tr>
                <tr>
                  <td>Organisation Id</td>
                  <td>{data.issuer.orgId ? data.issuer.orgId : '-'}</td>
                </tr>
              </tbody>
            </table>
            </div>
          : <h1>Η εγγραφή δεν βρέθηκε</h1>
        }
      </div>
    </div>
  )
}

export default Result;