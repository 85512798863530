export default {
  Footer: {
    services: 'Υπηρεσίες',
    info: 'Πληροφορίες',
    contact: 'Επικοινωνία',
    web: 'Ιστός',
    email: 'E-mail για Φορείς & ΑΕΙ',
    participatingInstitutions: 'Συμμετέχοντα Ιδρύματα',
    signatureVerification: 'Έλεγχος Εγκυρότητας Υπογραφής',
    diplomaCheck: 'Αυτεπάγγελτος Έλεγχος Τίτλων Σπουδών',
    termsOfUse: 'Όροι Χρήσης',
    revocation: 'Ανάκληση Εξουσιοδοτήσεων',
    templateHandlingApp: 'Εφαρμογή Διαχείρισης Προτύπων Τίτλων Σπουδών',
    developersGuide: 'Τεκμηρίωση'
  }
};