export default [
    {
        "value": "10",
        "name": "10 - Services"
    },
    {
        "value": "100",
        "name": "100 - Services not further defined"
    },
    {
        "value": "101",
        "name": "101 - Personal services"
    },
    {
        "value": "102",
        "name": "102 - Hygiene and occupational health services"
    },
    {
        "value": "103",
        "name": "103 - Security services"
    },
    {
        "value": "104",
        "name": "104 - Transport services"
    },
    {
        "value": "108",
        "name": "108 - Inter-disciplinary programmes and qualifications involving services"
    },
    {
        "value": "109",
        "name": "109 - Services not elsewhere classified"
    },
    {
        "value": "999",
        "name": "999 - Field unknown"
    },
    {
        "value": "1000",
        "name": "1000 - Services not further defined"
    },
    {
        "value": "1010",
        "name": "1010 - Personal services not further defined"
    },
    {
        "value": "1011",
        "name": "1011 - Domestic services"
    },
    {
        "value": "1012",
        "name": "1012 - Hair and beauty services"
    },
    {
        "value": "1013",
        "name": "1013 - Hotel, restaurants and catering"
    },
    {
        "value": "1014",
        "name": "1014 - Sports"
    },
    {
        "value": "1015",
        "name": "1015 - Travel, tourism and leisure"
    },
    {
        "value": "1019",
        "name": "1019 - Personal services not elsewhere classified"
    },
    {
        "value": "1020",
        "name": "1020 - Hygiene and occupational health services not further defined"
    },
    {
        "value": "1021",
        "name": "1021 - Community sanitation"
    },
    {
        "value": "1022",
        "name": "1022 - Occupational health and safety"
    },
    {
        "value": "1029",
        "name": "1029 - Hygiene and occupational health services not elsewhere classified"
    },
    {
        "value": "1030",
        "name": "1030 - Security services not further defined"
    },
    {
        "value": "1031",
        "name": "1031 - Military and defence"
    },
    {
        "value": "1032",
        "name": "1032 - Protection of persons and property"
    },
    {
        "value": "1039",
        "name": "1039 - Security services not elsewhere classified"
    },
    {
        "value": "1041",
        "name": "1041 - Transport services"
    },
    {
        "value": "1088",
        "name": "1088 - Inter-disciplinary programmes and qualifications involving services"
    },
    {
        "value": "1099",
        "name": "1099 - Services not elsewhere classified"
    },
    {
        "value": "9999",
        "name": "9999 - Field unknown"
    },
    {
        "value": "00",
        "name": "00 - Generic programmes and qualifications"
    },
    {
        "value": "01",
        "name": "01 - Education"
    },
    {
        "value": "02",
        "name": "02 - Arts and humanities"
    },
    {
        "value": "03",
        "name": "03 - Social sciences: journalism and information"
    },
    {
        "value": "04",
        "name": "04 - Business,administration and Law"
    },
    {
        "value": "05",
        "name": "05 - Natural sciences, mathematics and Statistics"
    },
    {
        "value": "06",
        "name": "06 - Information and Communication Technologies (ICTs)"
    },
    {
        "value": "07",
        "name": "07 - Engineering, manufacturing and construction"
    },
    {
        "value": "08",
        "name": "08 - Agriculture, forestry, fisheries and veterinary"
    },
    {
        "value": "09",
        "name": "09 - Health and welfare"
    },
    {
        "value": "000",
        "name": "000 - Generic programmes and qualifications not further defined"
    },
    {
        "value": "001",
        "name": "001 - Basic programmes and qualifications"
    },
    {
        "value": "002",
        "name": "002 - Literacy and numeracy"
    },
    {
        "value": "003",
        "name": "003 - Personal skills and development"
    },
    {
        "value": "009",
        "name": "009 - Generic programmes and qualifications not elsewhere classified"
    },
    {
        "value": "011",
        "name": "011 - Education"
    },
    {
        "value": "018",
        "name": "018 - Inter-disciplinary programmes and qualifications involving education"
    },
    {
        "value": "020",
        "name": "020 - Arts and humanities not further Defined"
    },
    {
        "value": "021",
        "name": "021 - Arts"
    },
    {
        "value": "022",
        "name": "022 - Humanities (except languages)"
    },
    {
        "value": "023",
        "name": "023 - Languages"
    },
    {
        "value": "028",
        "name": "028 - Inter-disciplinary programmes and qualifications involving arts and humanities"
    },
    {
        "value": "029",
        "name": "029 - Arts and humanities not elsewhere classified"
    },
    {
        "value": "030",
        "name": "030 - Social sciences, journalism and information not further defined"
    },
    {
        "value": "031",
        "name": "031 - Social and behavioural sciences "
    },
    {
        "value": "032",
        "name": "032 - Journalism and information "
    },
    {
        "value": "038",
        "name": "038 - Inter-disciplinary programmes and qualifications involving social sciences, journalism and information"
    },
    {
        "value": "039",
        "name": "039 - Social sciences, journalism and information not elsewhere classified"
    },
    {
        "value": "040",
        "name": "040 - Business, administration and law not further define"
    },
    {
        "value": "041",
        "name": "041 - Business and administration "
    },
    {
        "value": "042",
        "name": "042 - Law"
    },
    {
        "value": "048",
        "name": "048 - Inter-disciplinary programmes and qualifications involving business, administration and law"
    },
    {
        "value": "049",
        "name": "049 - Business, administration and law not elsewhere classified"
    },
    {
        "value": "050",
        "name": "050 - Natural sciences, mathematics and statistics not further defined"
    },
    {
        "value": "051",
        "name": "051 - Biological and related sciences "
    },
    {
        "value": "052",
        "name": "052 - Environment"
    },
    {
        "value": "053",
        "name": "053 - Physical sciences"
    },
    {
        "value": "054",
        "name": "054 - Mathematics and statistics "
    },
    {
        "value": "058",
        "name": "058 - Inter-disciplinary programmes and qualifications involving natural sciences, mathematics and statistics"
    },
    {
        "value": "059",
        "name": "059 - Natural sciences, mathematics and statistics not elsewhere classified"
    },
    {
        "value": "061",
        "name": "061 - Information and Communication Technologies (ICTs) "
    },
    {
        "value": "068",
        "name": "068 - Inter-disciplinary programmes and qualifications involving Information and Communication Technologies"
    },
    {
        "value": "070",
        "name": "070 - Engineering, manufacturing and construction not further defined"
    },
    {
        "value": "071",
        "name": "071 - Engineering and engineering trades "
    },
    {
        "value": "072",
        "name": "072 - Manufacturing and processing "
    },
    {
        "value": "073",
        "name": "073 - Architecture and construction "
    },
    {
        "value": "078",
        "name": "078 - Inter-disciplinary programmes and qualifications involving engineering manufacturing and construction"
    },
    {
        "value": "079",
        "name": "079 - Engineering, manufacturing and construction not elsewhere classified"
    },
    {
        "value": "080",
        "name": "080 - Agriculture, forestry, fisheries and veterinary not further defined"
    },
    {
        "value": "081",
        "name": "081 - Agriculture"
    },
    {
        "value": "082",
        "name": "082 - Forestry"
    },
    {
        "value": "083",
        "name": "083 - Fisheries"
    },
    {
        "value": "084",
        "name": "084 - Veterinary"
    },
    {
        "value": "088",
        "name": "088 - Inter-disciplinary programmes and qualifications involving agriculture, forestry,fisheries and veterinary"
    },
    {
        "value": "089",
        "name": "089 - Agriculture, forestry, fisheries and veterinary not elsewhere classified"
    },
    {
        "value": "090",
        "name": "090 - Health and welfare not further defined"
    },
    {
        "value": "091",
        "name": "091 - Health"
    },
    {
        "value": "092",
        "name": "092 - Welfare"
    },
    {
        "value": "098",
        "name": "098 - Inter-disciplinary programmes and qualifications involving health and welfare"
    },
    {
        "value": "099",
        "name": "099 - Health and welfare not elsewhere classified"
    },
    {
        "value": "0000",
        "name": "0000 - Generic programmes and qualifications not further defined"
    },
    {
        "value": "0011",
        "name": "0011 - Basic programmes and qualifications"
    },
    {
        "value": "0021",
        "name": "0021 - Literacy and numeracy"
    },
    {
        "value": "0031",
        "name": "0031 - Personal skills"
    },
    {
        "value": "0099",
        "name": "0099 - Generic programmes and qualifications not elsewhere classified"
    },
    {
        "value": "0110",
        "name": "0110 - Education not further defined"
    },
    {
        "value": "0111",
        "name": "0111 - Education science"
    },
    {
        "value": "0112",
        "name": "0112 - Training for pre-school teachers"
    },
    {
        "value": "0113",
        "name": "0113 - Teacher training without subject specialisation"
    },
    {
        "value": "0114",
        "name": "0114 - Teacher training with subject specialisation"
    },
    {
        "value": "0119",
        "name": "0119 - Education not elsewhere classified"
    },
    {
        "value": "0188",
        "name": "0188 - Inter-disciplinary programmes and qualifications involving education"
    },
    {
        "value": "0200",
        "name": "0200 - Arts and humanities not further Defined"
    },
    {
        "value": "0210",
        "name": "0210 - Arts not further defined"
    },
    {
        "value": "0211",
        "name": "0211 - Audio-visual techniques and media production"
    },
    {
        "value": "0212",
        "name": "0212 - Fashion, interior and industrial design"
    },
    {
        "value": "0213",
        "name": "0213 - Fine arts"
    },
    {
        "value": "0214",
        "name": "0214 - Handicrafts"
    },
    {
        "value": "0215",
        "name": "0215 - Music and performing arts"
    },
    {
        "value": "0219",
        "name": "0219 - Arts not elsewhere classified"
    },
    {
        "value": "0220",
        "name": "0220 - Humanities (except languages) not further defined"
    },
    {
        "value": "0221",
        "name": "0221 - Religion and theology"
    },
    {
        "value": "0222",
        "name": "0222 - History and archaeology"
    },
    {
        "value": "0223",
        "name": "0223 - Philosophy and ethics"
    },
    {
        "value": "0229",
        "name": "0229 - Humanities (except languages) not elsewhere classified"
    },
    {
        "value": "0230",
        "name": "0230 - Languages not further defined"
    },
    {
        "value": "0231",
        "name": "0231 - Language acquisition"
    },
    {
        "value": "0232",
        "name": "0232 - Literature and linguistics"
    },
    {
        "value": "0239",
        "name": "0239 - Languages not elsewhere classified"
    },
    {
        "value": "0288",
        "name": "0288 - Inter-disciplinary programmes and qualifications involving arts and humanities"
    },
    {
        "value": "0299",
        "name": "0299 - Arts and humanities not elsewhere classified"
    },
    {
        "value": "0300",
        "name": "0300 - Social sciences, journalism and information not further defined"
    },
    {
        "value": "0310",
        "name": "0310 - Social and behavioural sciences not further defined"
    },
    {
        "value": "0311",
        "name": "0311 - Economics"
    },
    {
        "value": "0312",
        "name": "0312 - Political sciences and civics"
    },
    {
        "value": "0313",
        "name": "0313 - Psychology"
    },
    {
        "value": "0314",
        "name": "0314 - Sociology and cultural studies"
    },
    {
        "value": "0319",
        "name": "0319 - Social and behavioural sciences not elsewhere classified"
    },
    {
        "value": "0320",
        "name": "0320 - Journalism and information not further defined"
    },
    {
        "value": "0321",
        "name": "0321 - Journalism and reporting"
    },
    {
        "value": "0322",
        "name": "0322 - Library, information and archival studies"
    },
    {
        "value": "0329",
        "name": "0329 - Journalism and information not elsewhere classified"
    },
    {
        "value": "0388",
        "name": "0388 - Inter-disciplinary programmes and qualifications involving social sciences, journalism and information"
    },
    {
        "value": "0399",
        "name": "0399 - Social sciences, journalism and information not elsewhere classified"
    },
    {
        "value": "0400",
        "name": "0400 - Business, administration and law not further defined"
    },
    {
        "value": "0410",
        "name": "0410 - Business and administration not further defined"
    },
    {
        "value": "0411",
        "name": "0411 - Accounting and taxation"
    },
    {
        "value": "0412",
        "name": "0412 - Finance, banking and insurance"
    },
    {
        "value": "0413",
        "name": "0413 - Management and administration"
    },
    {
        "value": "0414",
        "name": "0414 - Marketing and advertising"
    },
    {
        "value": "0415",
        "name": "0415 - Secretarial and office work"
    },
    {
        "value": "0416",
        "name": "0416 - Wholesale and retail sales"
    },
    {
        "value": "0417",
        "name": "0417 - Work skills"
    },
    {
        "value": "0419",
        "name": "0419 - Business and administration not elsewhere classified"
    },
    {
        "value": "0421",
        "name": "0421 - Law"
    },
    {
        "value": "0488",
        "name": "0488 - Inter-disciplinary programmes and qualifications involving business, administration and law"
    },
    {
        "value": "0499",
        "name": "0499 - Business, administration and law not elsewhere classified"
    },
    {
        "value": "0500",
        "name": "0500 - Natural sciences, mathematics and statistics not further defined"
    },
    {
        "value": "0510",
        "name": "0510 - Biological and related sciences not further defined"
    },
    {
        "value": "0511",
        "name": "0511 - Biology"
    },
    {
        "value": "0512",
        "name": "0512 - Biochemistry"
    },
    {
        "value": "0519",
        "name": "0519 - Biological and related sciences not elsewhere classified"
    },
    {
        "value": "0520",
        "name": "0520 - Environment not further defined"
    },
    {
        "value": "0521",
        "name": "0521 - Environmental sciences"
    },
    {
        "value": "0522",
        "name": "0522 - Natural environments and wildlife"
    },
    {
        "value": "0529",
        "name": "0529 - Environment not elsewhere classified"
    },
    {
        "value": "0530",
        "name": "0530 - Physical sciences not further defined"
    },
    {
        "value": "0531",
        "name": "0531 - Chemistry"
    },
    {
        "value": "0532",
        "name": "0532 - Earth sciences"
    },
    {
        "value": "0533",
        "name": "0533 - Physics"
    },
    {
        "value": "0539",
        "name": "0539 - Physical sciences not elsewhere classified"
    },
    {
        "value": "0540",
        "name": "0540 - Mathematics and statistics not further defined"
    },
    {
        "value": "0541",
        "name": "0541 - Mathematics"
    },
    {
        "value": "0542",
        "name": "0542 - Statistics"
    },
    {
        "value": "0588",
        "name": "0588 - Inter-disciplinary programmes and qualifications involving natural sciences, mathematics and statistics"
    },
    {
        "value": "0599",
        "name": "0599 - Natural sciences, mathematics and statistics not elsewhere classified"
    },
    {
        "value": "0610",
        "name": "0610 - Information and Communication Technologies (ICTs) not further defined"
    },
    {
        "value": "0611",
        "name": "0611 - Computer use"
    },
    {
        "value": "0612",
        "name": "0612 - Database and network design and administration"
    },
    {
        "value": "0613",
        "name": "0613 - Software and applications development and analysis"
    },
    {
        "value": "0619",
        "name": "0619 - Information and Communication Technologies not elsewhere classified"
    },
    {
        "value": "0688",
        "name": "0688 - Inter-disciplinary programmes and qualifications involving Information and Communication Technologies"
    },
    {
        "value": "0700",
        "name": "0700 - Engineering, manufacturing and construction not further defined"
    },
    {
        "value": "0710",
        "name": "0710 - Engineering and engineering trades not further defined"
    },
    {
        "value": "0711",
        "name": "0711 - Chemical engineering and processes"
    },
    {
        "value": "0712",
        "name": "0712 - Environmental protection technology"
    },
    {
        "value": "0713",
        "name": "0713 - Electricity and energy"
    },
    {
        "value": "0714",
        "name": "0714 - Electronics and automation"
    },
    {
        "value": "0715",
        "name": "0715 - Mechanics and metal trades"
    },
    {
        "value": "0716",
        "name": "0716 - Motor vehicles, ships and aircraft"
    },
    {
        "value": "0719",
        "name": "0719 - Engineering and engineering trades not elsewhere classified"
    },
    {
        "value": "0720",
        "name": "0720 - Manufacturing and processing not further defined"
    },
    {
        "value": "0721",
        "name": "0721 - Food processing"
    },
    {
        "value": "0722",
        "name": "0722 - Materials (glass, paper, plastic and wood)"
    },
    {
        "value": "0723",
        "name": "0723 - Textiles (clothes, footwear and leather)"
    },
    {
        "value": "0724",
        "name": "0724 - Mining and extraction"
    },
    {
        "value": "0729",
        "name": "0729 - Manufacturing and processing not elsewhere classified"
    },
    {
        "value": "0730",
        "name": "0730 - Architecture and construction not further defined"
    },
    {
        "value": "0731",
        "name": "0731 - Architecture and town planning"
    },
    {
        "value": "0732",
        "name": "0732 - Building and civil engineering"
    },
    {
        "value": "0788",
        "name": "0788 - Inter-disciplinary programmes and qualifications involving engineering manufacturing and construction"
    },
    {
        "value": "0799",
        "name": "0799 - Engineering, manufacturing and construction not elsewhere classified"
    },
    {
        "value": "0800",
        "name": "0800 - Agriculture, forestry, fisheries and veterinary not further defined"
    },
    {
        "value": "0810",
        "name": "0810 - Agriculture not further defined"
    },
    {
        "value": "0811",
        "name": "0811 - Crop and livestock production"
    },
    {
        "value": "0812",
        "name": "0812 - Horticulture"
    },
    {
        "value": "0819",
        "name": "0819 - Agriculture not elsewhere classified"
    },
    {
        "value": "0821",
        "name": "0821 - Forestry"
    },
    {
        "value": "0831",
        "name": "0831 - Fisheries"
    },
    {
        "value": "0841",
        "name": "0841 - Veterinary"
    },
    {
        "value": "0888",
        "name": "0888 - Inter-disciplinary programmes and qualifications involving agriculture, forestry, fisheries and veterinary"
    },
    {
        "value": "0899",
        "name": "0899 - Agriculture, forestry, fisheries and veterinary not elsewhere classified"
    },
    {
        "value": "0900",
        "name": "0900 - Health and welfare not further defined"
    },
    {
        "value": "0910",
        "name": "0910 - Health not further defined"
    },
    {
        "value": "0911",
        "name": "0911 - Dental studies"
    },
    {
        "value": "0912",
        "name": "0912 - Medicine"
    },
    {
        "value": "0913",
        "name": "0913 - Nursing and midwifery"
    },
    {
        "value": "0914",
        "name": "0914 - Medical diagnostic and treatment technology"
    },
    {
        "value": "0915",
        "name": "0915 - Therapy and rehabilitation"
    },
    {
        "value": "0916",
        "name": "0916 - Pharmacy"
    },
    {
        "value": "0917",
        "name": "0917 - Traditional and complementary medicine and therapy"
    },
    {
        "value": "0919",
        "name": "0919 - Health not elsewhere classified"
    },
    {
        "value": "0920",
        "name": "0920 - Welfare not further defined"
    },
    {
        "value": "0921",
        "name": "0921 - Care of elderly and of disabled adults"
    },
    {
        "value": "0922",
        "name": "0922 - Child care and youth services"
    },
    {
        "value": "0923",
        "name": "0923 - Social work and counselling"
    },
    {
        "value": "0929",
        "name": "0929 - Welfare not elsewhere classified"
    },
    {
        "value": "0988",
        "name": "0988 - Inter-disciplinary programmes and qualifications involving health and welfare"
    },
    {
        "value": "0999",
        "name": "0999 - Health and welfare not elsewhere classified"
    }
]