
import './App.css';
import Header from './Header';
import Footer from './Footer';
import LocaleEl from './i18n/locale-el';
import LocaleEn from './i18n/locale-en';
import { getQueryParams } from './lib/Util';


import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Polyglot from 'node-polyglot';

import Search from './Search'
import KPIs from './KPIs'
import SearchResults from './SearchResults';
import Result from './Result';

const POLYGLOT = {
  el: new Polyglot({ phrases: LocaleEl, locale: 'el' }),
  en: new Polyglot({ phrases: LocaleEn, locale: 'en' })
};
class App extends Component {

  constructor(props){
    super(props)
    this.state = {
      lang: 'el',
      error: false,
      loading: 0,
      searchPhrase: '',
      results: {},
      redirect: false
    };
  }

  handleLanguage = (lang) => {
    if (lang !== 'el' && lang !== 'en') {
      lang = 'en';
    }
    this.setState({ lang });
    sessionStorage.setItem('lang', lang);
  };

  componentDidMount = () => {
    const location = window.location.toString();
    let lang = sessionStorage.getItem('lang');
    const queryParams = getQueryParams();
    lang = 'lang' in queryParams ? queryParams.lang : lang;
    if (lang) {
      this.handleLanguage(lang);
    }
  };

  render = () => (
    <BrowserRouter basename="/search">
      <div className="box">
        <div className="row header">
          <Header polyglot={POLYGLOT[this.state.lang]} />
        </div>
        <div className="row content">
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <Search />
                  <KPIs/>
                </div>
              }
            />
            <Route
              path="/results/templates/:id"
              element={
                <Result/>
              }
            />
            <Route 
              path="/results"
              element = {
                <SearchResults/>
              }
            />
          </Routes>
        </div>
      </div>
      {/* <Footer polyglot={POLYGLOT[this.state.lang]} /> */}
    </BrowserRouter>
  );
}

export default App;
