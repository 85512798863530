export default {
    Footer: {
      services: 'Services',
      info: 'Information',
      contact: 'Contact',
      web: 'Web',
      email: 'E-mail for Organizations and HEIs',
      participatingInstitutions: 'Participating Institutions',
      signatureVerification: 'Signature Verification',
      diplomaCheck: 'Ex-officio Degree Check',
      termsOfUse: 'Terms of Use',
      revocation: 'Authorization Revocation',
      templateHandlingApp: 'Degree Template Administration Service',
      developersGuide: 'Documentation'
    }
};