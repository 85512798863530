import React, { useEffect, useState } from 'react';
import Urls from './Urls'
import { Spinner } from './Spinner';

import './KPIs.css';


const KPIs = () => {
  const [data, setData] = useState({
    institutions: null,
    maintainers: null,
    departments: null,
    curricula: null,
    templates: null
  });
  const [loading, setLoading] = useState(false)

  
  useEffect(() => {
    const getKpis = async () => {
      try {
        setLoading(true)
        const res = await fetch(Urls.blueprintsUrl + '/kpis', {
          method: 'GET',
        });
        const resJson = await res.json()
        setData({
          ...data,
          institutions: resJson.institutions,
          maintainers: resJson.maintainers,
          departments: resJson.departments,
          curricula: resJson.curricula,
          templates: resJson.templates
        });
        setLoading(false)
      } catch (e) {
        console.log(e);
        setLoading(false)
      }
    };
    getKpis()
  }, []);

  return (
    <div className='home-stats'>
      {loading ? <Spinner cx={80} cy={80} r={38}/> :
        <div className='col-md-4'>
          <div className='item'>
            <div className='col list-categories-header'>
              <span className="highlighted-span">{data.institutions}</span>
              <br />
              <h3 className="stat-title" >Ιδρύματα <br />Έκδοσης</h3>
            </div>
          </div>
          <div className='item'>
            <div className='col list-categories-header'>
              <span className="highlighted-span">{data.maintainers}</span>
              <br />
              <h3 className="stat-title" >Αρμόδια <br />Τμήματα</h3>
            </div>
          </div>
          <div className='item'>
            <div className='col list-categories-header'>
              <span className="highlighted-span">{data.departments}</span>
              <br />
              <h3 className="stat-title" >Τμήματα <br />Έκδοσης</h3>
            </div>
          </div>
          <div className='item'>
            <div className='col list-categories-header'>
              <span className="highlighted-span">{data.curricula}</span>
              <br />
              <h3 className="stat-title" >Προγράμματα <br />Σπουδών</h3>
            </div>
          </div>
          <div className='item'>
            <div className='col list-categories-header'>
              <span className="highlighted-span">{data.templates}</span>
              <br />
              <h3 className="stat-title" >Μήτρες <br />Τίτλων</h3>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default KPIs;