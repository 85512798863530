import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Select from 'react-select'
import { Spinner } from './Spinner';
import ReactPaginate from 'react-paginate';
import foe from './data/foe';
import './App.css';
import './SearchResults.css';
import Urls from './Urls'


const SearchResults = () => {

    const levelOptions = [
        { value: '', name: '' },
        { value: '6', name: '6 - Bachelor\'s or equivalent level' },
        { value: '64', name: '64 - Academic' },
        { value: '641', name: '641 - Insufficient for level completion' },
        { value: '645', name: '645 - First degree (3–4 years)' },
        { value: '646', name: '646 - Long first degree (more than 4 years)' },
        { value: '647', name: '647 - Second or further degree (following a bachelor\'s or equivalent programme)' },
        { value: '65', name: '65 - Professional' },
        { value: '651', name: '651 - Insufficient for level completion' },
        { value: '655', name: '655 - First degree (3–4 years)' },
        { value: '656', name: '656 - Long first degree (more than 4 years)' },
        { value: '657', name: '657 - Second or further degree (following a bachelor\'s or equivalent programme)' },
        { value: '66', name: '66 - Orientation unspecified' },
        { value: '661', name: '661 - Insufficient for level completion' },
        { value: '665', name: '665 - First degree (3–4 years)' },
        { value: '666', name: '666 - Long first degree (more than 4 years)' },
        { value: '667', name: '667 - Second or further degree (following a bachelor\'s or equivalent programme)' },
        { value: '7', name: '7 - Master\'s or equivalent level' },
        { value: '74', name: '74 - Academic' },
        { value: '741', name: '741 - Insufficient for level completion' },
        { value: '746', name: '746 - Long first degree (at least 5 years)' },
        { value: '747', name: '747 - Second or further degree (following a bachelor\'s or equivalent programme)' },
        { value: '748', name: '748 - Second or further degree (following a master\'s or equivalent programme)' },
        { value: '75', name: '75 - Professional' },
        { value: '751', name: '751 - Insufficient for level completion' },
        { value: '756', name: '756 - Long first degree (at least 5 years)' },
        { value: '757', name: '757 - Second or further degree (following a bachelor\'s or equivalent programme)' },
        { value: '758', name: '758 - Second or further degree (following a master\'s or equivalent programme)' },
        { value: '76', name: '76 - Orientation unspecified' },
        { value: '761', name: '761 - Insufficient for level completion' },
        { value: '766', name: '766 - Long first degree (at least 5 years)' },
        { value: '767', name: '767 - Second or further degree (following a bachelor\'s or equivalent programme)' },
        { value: '768', name: '768 - Second or further degree (following a master\'s or equivalent programme) ' }
    ];

    const templateStateOptions = [
        { name: 'Προς Έγκριση', value: 1 },
        { name: 'Υπό Επεξεργασία', value: 0 }
    ];

    const comparisonOptions = [
        { name: 'Τουλάχιστον Ένα', value: 1 },
        { name: 'Όλα', value: 0 }
    ];

    class TextFilter {
        constructor() {
            this.type = "text";
            this._value = '';
        }

        set value(e) {
            if (!e) {
                this._value = '';
                return;
            }
            this._value = e.value
        }

        get value() {
            return this._value;
        }
    }

    class SelectFilter {
        constructor() {
            this.type = "select";
            this.options = [];
            this._value = null;
        }

        set value(e) {
            if (e === null || e === undefined) {
                this._value = null;
                return;
            }
            this._value = e.value;
        }

        get value() {
            return this._value;
        }

        get selectValue() {
            if (this._value === null || this._value === undefined) return null;
            return { label: this._value, value: this._value }
        }
    }

    class LevelFilter extends SelectFilter {
        get selectValue() {
            if (this._value === null || this._value === undefined) return null;
            const options = levelOptions
            let label = options.find(option => option.value == this._value).name
            return { label: label, value: this._value }
        }
    }

    class ComparisonFilter extends SelectFilter {
        get selectValue() {
            if (this._value === null || this._value === undefined) return null;
            const options = comparisonOptions
            let label = options.find(option => option.value == this._value).name
            return {label: label, value: this._value}
        }
    }

    class MultiFilter {
        constructor() {
            this.type = "multi";
            this.options = [];
            this._value = [];
        }

        set value(e) {
            this._value = e;
        }

        get value() {
            if (Array.isArray(this._value) && this._value.length === 0) {
                return null;
            }
            return this._value;
        }

        get selectValue() {
            if (this._value === null || this._value === undefined) return [];
            return this._value
        }
    }

    // const { state } = useLocation(); //state passed from Search.js has the 'searchPhrase' attribute
    let [queryParams, setQueryParams] = useSearchParams();
    const [startingPage, setStartingPage] = useState(0);
    const [searchPhrase, setSearchPhrase] = useState(null);
    const [activeFilters, setActiveFilters] = useState([]);
    const [filters, setFilters] = useState({
        "minedu_id": new TextFilter(),
        "homeOrg": new SelectFilter(),
        "institutionName": new SelectFilter(),
        "departmentName": new SelectFilter(),
        "maintainerName": new SelectFilter(),
        "level": new LevelFilter(),
        "foe": new MultiFilter(),
        // "foeComparison": new ComparisonFilter()
    })
    const [results, setResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [maintainers, setMaintainers] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [levels, setLevels] = useState([]);
    const [homeOrgs, setHomeOrgs] = useState([]);
    const [compDepartments, setCompDepartments] = useState([]);
    const [compMaintainers, setCompMaintainers] = useState([]);
    const [compInstitutions, setCompInstitutions] = useState([]);
    const [loading, setLoading] = useState(0);
    const [searchMade, setSearchMade] = useState(false);
    const [inputText, setInputText] = useState("");

    //pagination
    const maxRows = 15; //the number of rows in each page
    const [resultsPagination, setResultsPagination] = useState({
        currentStart: 0,
        currentEnd: 0,
        pageCount: 0
    })

    const handlePagination = (page, results) => {
        let currentStart = (page) * maxRows;
        let pageResults = results.slice(currentStart)
        let currentEnd = pageResults.length >= maxRows ? currentStart + maxRows : currentStart + pageResults.length
        return {currentStart, currentEnd}
    }

    const activeHandlePageClick = (data) => {
        
        let page = data.selected
        let paginationData = handlePagination(page, filteredResults)
        setResultsPagination({...resultsPagination, ...paginationData})
        const params = {
            searchall: searchPhrase ?? "",
            page: page
        }

        for (const filter of activeFilters) {
            if (filter[0] !== 'searchall' && filter[0] !== 'page') {
                params[filter[0]] = filter[1]
            }
        }
        setStartingPage(page)
        setQueryParams(params);
        // setQueryParams({...queryParams.entries(), page: page})
        
    }

    //find and return the index of the provided filter id in the array
    const findIdIndex = (array, targetId) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === targetId)
                return i;
        }
    }

    const handleFilters = async (e, name) => {
        if (!results || results.length === 0) {
            // If we dont have any results and filters are being added, we must stop and fetch
            if (searchPhrase === null) {
                await search("")
            } else {
                await search(searchPhrase)
            }
        }

        //if the event is null, an individual filter's clear button has been pressed and it should be removed from the state hook
        let newActiveFilters = [];
        if (!e) {
            // setActiveFilters(newActiveFilters = activeFilters.filter(filter => filter[0] !== name))
            const filterCopy = filters;
            filterCopy[name].value = null;
            setFilters({ ...filterCopy })
        }
        else {
            const filterCopy = filters;
            filterCopy[name].value = e;
            setFilters({ ...filterCopy })
        }
        // let paginationData = handlePagination(0, filteredResults)
        // let pageCount = Math.floor(filteredResults.length / maxRows)
        // pageCount = filteredResults.length % maxRows ? (pageCount + 1) : pageCount
        // setResultsPagination({...resultsPagination, ...paginationData, pageCount })
        setStartingPage(0)
    }

    const search = async (searchTerm) => {
        //fetch data matching the searchPhrase
        // setQueryParams({'searchall': searchTerm})

        setSearchMade(true);
        let res; let resJson;
        try {
            setLoading(loading => loading + 1);
            if (searchTerm)
                res = await fetch(Urls.blueprintsUrl + '/templates/?searchall=' + searchTerm);
            else //empty searchPhrase brings back all results unfiltered
                res = await fetch(Urls.blueprintsUrl + '/templates/?searchall=');
            resJson = await res.json();
            //replace the data of the state hooks
            setResults(resJson);
        } catch (e) {
            console.log(e);
            setLoading(loading => loading - 1);
        }
        setLoading(loading => loading - 1);
    };

    const submitForm = (e) => {
        e.preventDefault()
        setSearchPhrase(inputText)
        setStartingPage(0);
    }

    const fetchAPIData = async () => {
        let res; let resJson;
        try {
            setLoading(loading => loading + 1);
            res = await fetch(Urls.blueprintsUrl + "/department");
            resJson = await res.json();
            setDepartments(resJson);
            res = await fetch(Urls.blueprintsUrl + "/maintainer/");
            resJson = await res.json();
            setMaintainers(resJson);
            res = await fetch(Urls.blueprintsUrl + "/institution/");
            resJson = await res.json();
            setInstitutions(resJson);
            res = await fetch(Urls.blueprintsUrl + "/orgs");
            resJson = await res.json();
            setHomeOrgs(Object.keys(resJson).map(item => (
                { name: item }
            )));
            res = await fetch(Urls.blueprintsUrl + "/levels/");
            resJson = await res.json();
            setLevels(resJson.map(item => (
                {
                    value: item.level,
                    name: levelOptions.find(option => option.value == item.level).name
                }
            )));
        } catch (e) {
            setLoading(loading => loading - 1);
            console.log(e);
        }
        setLoading(loading => loading - 1);
    };

    useEffect(() => {
        setSearchPhrase(queryParams.get('searchall'))
        fetchAPIData()
        setInputText(queryParams.get('searchall'));
        setStartingPage(Number(queryParams.get('page')) ?? 0)
        // Get filters from parameters
        const filtersCopy = filters;
        for (const param of queryParams) {
            if (param[0] !== 'searchall' && param[0] !== 'page') {
                filtersCopy[param[0]].value={value: param[1]}
            }
        }
        setFilters({ ...filtersCopy })
        // setActiveFilters(filters)
    }, []);

    useEffect(() => {
        // const active = Object.keys(filters).map(filterKey => [filterKey, filters[filterKey]]).filter(filter => filter.value != null);
        let active = [];
        for (const filter of Object.keys(filters)) {
            if (filters[filter].value !== null && filters[filter].value !== undefined && filters[filter].value !== '') { // skip empty filters
                active.push([filter, filters[filter].value])
            }
        }
        setActiveFilters(active)
    }, [filters])

    useEffect(() => {
        if (searchPhrase === null && results) {
            setInputText(''); //set search phrase to '' in case the user decides to make an empty search after he's redirected with 'null'
        } else {
            setInputText(searchPhrase);
            search(searchPhrase)
        }
    }, [searchPhrase])

    useEffect(() => {
        console.log("Active:", activeFilters, startingPage)
        // This is what used to be handleFiltering
        if (!results) {
            return;
        }

        if (!activeFilters) {
            return;
        }

        const existingDepartments = new Set();
        const existingInstitutions = new Set();
        const existingMaintainers = new Set();

        let filteredResults = results.filter((item) => { //for every result
            for (const filter of activeFilters) { //check against each active filter
                if ((filter[0] === 'level' && item.curriculum.level !== filter[1])
                    || (filter[0] === 'departmentName' && !item.issuer.unit.includes(filter[1]))
                    || (filter[0] === 'maintainerName' && !item.curriculum.maintainer.unit.includes(filter[1]))
                    || (filter[0] === 'institutionName' && !item.issuer.institution.includes(filter[1]))
                    || (filter[0] === 'minedu_id' && !(item.curriculum.minedu_id == Number(filter[1])))
                    || (filter[0] === 'homeOrg' && !(item.provider === filter[1]))
                    || (filter[0] === 'foe' && item.curriculum.foe_list.filter(itemFoe => filter[1].map(fullVal => fullVal.value).includes(itemFoe)).length === 0)
                )
                    return false;
            }
            existingDepartments.add(item.issuer.unit);
            existingInstitutions.add(item.issuer.institution);
            existingMaintainers.add(item.curriculum.maintainer.unit);
            return true;
        });
        setFilteredResults(filteredResults);
        setCompDepartments(Array.from(existingDepartments).map(item => ({ unit: item })));
        setCompInstitutions(Array.from(existingInstitutions).map(item => ({ institution: item })));
        setCompMaintainers(Array.from(existingMaintainers).map(item => ({ unit: item })));

        //for pagination
        let paginationData = handlePagination(startingPage, filteredResults)
        let pageCount = Math.floor(filteredResults.length / maxRows)
        pageCount = filteredResults.length % maxRows ? (pageCount + 1) : pageCount
        setResultsPagination({ ...resultsPagination, ...paginationData, pageCount })
    }, [activeFilters, results])

    useEffect(() => {
        const params = {
            searchall: searchPhrase ?? "",
            page: startingPage
        }

        for (const filter of activeFilters) {
            if (filter[0] !== 'searchall' && filter[0] !== 'page' && filter[0] !== 'foe') {
                params[filter[0]] = filter[1]
            }
        }
        setQueryParams(params);
    }, [activeFilters, searchPhrase]);

    return (
        <div>
            <div className="results-container">
                <div className="filters">
                    <div className="resultsSearchWrapper">
                        <form className='grid-form' onSubmit={submitForm}>
                            <input type="text"
                                placeholder={"Αναζήτηση Τίτλων Σπουδών..."}
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}>
                            </input>
                            <button type='submit' className="turnLighter">
                                <span className='fas fa-search'></span>
                            </button>
                        </form>
                    </div>
                    <div className="filterset">
                        <h2>Ιδρύματα/Τμήματα</h2>
                        {/* populate department,maintainer and institution dropdown filters using API data */}
                        <label htmlFor="institutionName">Home org: </label>
                        <CustomSelect value={filters["homeOrg"].selectValue} name="homeOrg" data={homeOrgs} handleActiveFilters={handleFilters} />
                        <label htmlFor="institutionName">Ίδρυμα: </label>
                        <CustomSelect value={filters["institutionName"].selectValue} name="institutionName" data={activeFilters.length === 0 ? institutions : compInstitutions} handleActiveFilters={handleFilters} />
                        <label htmlFor="departmentName">Τμήμα Έκδοσης </label>
                        <CustomSelect value={filters["departmentName"].selectValue} name="departmentName" data={activeFilters.length === 0 ? departments : compDepartments} handleActiveFilters={handleFilters} />
                        <label htmlFor="maintainerName">Αρμόδιο Τμήμα: </label>
                        <CustomSelect value={filters["maintainerName"].selectValue} name="maintainerName" data={activeFilters.length === 0 ? maintainers : compMaintainers} handleActiveFilters={handleFilters} />
                    </div>
                    <div className="filterset">
                        <h2>Στοιχεία Προγράμματος Σπουδών</h2>
                        <label htmlFor="level">Επίπεδο Σπουδών (ISCED 2011): </label>
                        <CustomSelect value={filters["level"].selectValue} name="level" data={levels} handleActiveFilters={handleFilters} />
                        <label htmlFor="minedu_id">Κωδ. Πανελληνίων: </label>
                        <input value={filters['minedu_id'].value} id="minedu_id" name="minedu_id" type="text" onChange={async (e) => await handleFilters(e.target, "minedu_id")}></input>
                        <label htmlFor="foe">Γνωστικά Αντικείμενα (ISCED-F): </label>
                        {/* <CustomSelect value={filters["foeComparison"].selectValue} name="foeComparison" data={comparisonOptions} handleActiveFilters={handleFilters} /> */}
                        <CustomSelect isMulti value={filters["foe"].selectValue} name="foe" data={foe} handleActiveFilters={handleFilters} />
                    </div>
                </div>
                <div className="results">
                    {loading !== 0 && <div className='spinner'><Spinner cx={120} cy={120} r={58} /></div>}
                    {loading === 0 && filteredResults.length !== 0 && <div className="resultsWrapper">
                        <table className="resultsTable">
                            <thead>
                                <tr>
                                    <th className="wide">Τίτλος</th>
                                    <th className="wide">Τμήμα Έκδοσης</th>
                                    <th className="wide">Αρμόδιο Τμήμα</th>
                                    <th>Κωδ. Παν/ων</th>
                                    <th>Επίπεδο Σπουδών (ISCED 2011)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredResults.slice(resultsPagination.currentStart, resultsPagination.currentEnd).map((item) => (
                                    <tr key={item.id}>
                                        <td><Link to={'/results/templates/' + item.id}>{item.title}</Link></td>
                                        <td>{item.issuer.unit} | {item.issuer.institution}</td>
                                        <td>{item.curriculum.maintainer.unit}{' '}({item.curriculum.maintainer.institution_code})</td>
                                        <td>{item.curriculum.minedu_id}</td>
                                        <td>{item.curriculum.level}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {loading === 0 && <div className="tableFooter" style={{ borderTop: filteredResults.length !== 0 ? "1px solid rgb(227, 227, 227)" : "none" }}>
                        {searchMade &&
                            (filteredResults.length === 0
                                ?
                                <h1 className='no-data'>Δεν βρέθηκαν αποτελέσματα</h1>
                                :
                                <div className="countResultsSection">
                                    {filteredResults.length === 1
                                        ? <p>Βρέθηκε {filteredResults.length} αποτέλεσμα</p>
                                        : <p>Βρέθηκαν {filteredResults.length} αποτελέσματα</p>
                                    }
                                </div>
                            )
                        }
                        {filteredResults.length !== 0 &&
                            <div className="paginationWrapper">
                                <div className="centerPagination">
                                    <ReactPaginate
                                        previousLabel={"\u3008"}
                                        nextLabel={"\u3009"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        // forcePage={resultsPagination.currentPage}
                                        forcePage={startingPage}
                                        pageCount={resultsPagination.pageCount}
                                        //marginPagesDisplayed={this.props.mobile ? 1  : 2}
                                        //pageRangeDisplayed={this.props.mobile ? 1 : 3}
                                        onPageChange={activeHandlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        }
                    </div>}
                </div>
            </div>
        </div>
    );
}

//styling for <Select> components 
const selectStyles = {
    control: (styles) => {
        return {
            ...styles,
            width: '100%',
            height: '23px',
            boxSizing: 'border-box',
            marginBottom: '8px'
        };
    },
    menu: (styles) => {
        return {
            ...styles,
            width: '500px'
        };
    }
};

const multiSelectStyles = {
    control: (styles) => {
        return {
            ...styles,
            width: '100%',
            height: 'auto',
            boxSizing: 'border-box',
            marginBottom: '8px'
        };
    },
    menu: (styles) => {
        return {
            ...styles,
            width: '500px'
        };
    }
}

//custom <Select> component
const CustomSelect = (props) => {
    let options;
    if (props.name === 'level' || props.name === 'foe') {
        options = props.data.map((option) => (
            { value: option.value, label: option.name }
        ))
    }
    else if(props.name === 'institutionName') {
        options = props.data.map((option) => (
            { value: option.institution, label: option.institution }
        ))
    }
    else if(props.name === 'departmentName' || props.name === 'maintainerName') {
        options = props.data.map((option) => (
            { value: option.unit, label: option.unit }
        ))
    }
    else {
        options = props.data.map((option) => (
            { value: option.name, label: option.name }
        ))
    }
    return (<Select id={props.name} name={props.name}
        isClearable
        placeholder={"Επιλέξτε..."}
        onChange={async (e) => await props.handleActiveFilters(e, props.name)}
        options={options}
        styles={props.isMulti ? multiSelectStyles : selectStyles}
        value={props.value}
        isMulti={props.isMulti}
    />)
};

export default SearchResults;
