const scrollTop = () => {
    const top = document.documentElement.scrollTop || document.body.scrollTop;
    if (top > 0) {
      window.requestAnimationFrame(scrollTop);
      window.scrollTo(0, 0);
    }
  };
  
  const getQueryParams = () => {
    const location = window.location.toString();
    const queryStart = location.indexOf('?');
    let queryEnd = location.indexOf('#');
    const params = {};
    if (queryEnd < 0) {
      queryEnd = location.length;
    }
    if (queryStart >= 0) {
      const query = location.substring(queryStart + 1, queryEnd);
      const queryPairs = query.split('&');
  
      queryPairs.map((pair) => {
        const queryPair = pair.split('=');
        params[queryPair[0]] = queryPair[1];
      });
    }
    return params;
  };
  
  export { scrollTop, getQueryParams };
  